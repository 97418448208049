import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.scss";
import Loader from "./layouts/Loader/Loader";
import Auth from "./Authentication/auth";
import Error404 from "./components/Custompages/Error1-404/Error-404";
import { ProtectedRoute } from "./Router/ProtectedRoute";
import { Provider } from "react-redux";
import store from "./Redux/store";
import { ThemeProvider } from "@mui/material";
import theme from "./components/theme";
import CssBaseline from "@mui/material/CssBaseline";
import SectorTable from "./Pages/Dashboard/Table/SectorTable";
import StockTable from "./Pages/Dashboard/Table/StockTable";

// ** Dashboard
const Dashboard = React.lazy(() => import("./Pages/Dashboard/Dashboard"));
const App = React.lazy(() => import("./components/app"));

// ** Organization List
const OrganizationList = React.lazy(() =>
  import("./Pages/Organization/OrganizationList")
);

// ** Sector
const SectorList = React.lazy(() => import("./Pages/Sector/SectorList"));

// ** Stocks
const StocksList = React.lazy(() => import("./Pages/Stocks/StocksList"));

// ** User
const UserList = React.lazy(() => import("./Pages/User/UserList"));
const UserLoanList = React.lazy(() => import("./Pages/UserLoan/UserLoanList"));

const Setting = React.lazy(() => import("./Pages/Globle-Setting/Setting"));

const AuthLogin = React.lazy(() => import("./Authentication/Login"));

const NotificationList = React.lazy(() =>
  import("./Pages/Notification/Notification")
);
const Portfolio = React.lazy(() => import("./Pages/Portfolio/Portfolio"));

const LoanDetails = React.lazy(() => import("./Pages/UserLoan/LoanDetails"));

const Root = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter basename={`${process.env.PUBLIC_URL}`}>
          <React.Suspense fallback={<Loader />}>
            <Routes>
              <Route path={`/`} element={<Auth />}>
                <Route index element={<AuthLogin />} />
                <Route path={`/login`} element={<AuthLogin />} />
              </Route>
              <Route
                path={`/`}
                element={
                  <ProtectedRoute>
                    <App />
                  </ProtectedRoute>
                }
              >
                <Route path={`/dashboard`} element={<Dashboard />} />
                <Route
                  path={`/dashboard/profit-sector`}
                  element={<SectorTable />}
                />
                <Route
                  path={`/dashboard/profit-stocks`}
                  element={<StockTable />}
                />

                <Route path={`/organization`} element={<OrganizationList />} />

                <Route path={`/sector`} element={<SectorList />} />

                <Route path={`/stocks`} element={<StocksList />} />
                <Route path={`/users`} element={<UserList />} />
                <Route path={`/user-loan`} element={<UserLoanList />} />
                <Route
                  path={`/user-loan/details/:id`}
                  element={<LoanDetails />}
                />

                <Route path={`/setting`} element={<Setting />} />
                <Route path={`/notification`} element={<NotificationList />} />
                <Route path={`/portfolio`} element={<Portfolio />} />

                {/* Errors */}
              </Route>

              {/* ........................................Errorpage............................................... */}
              <Route path="*" element={<Error404 />} />
            </Routes>
          </React.Suspense>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
};
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);
